div .appBar {
  border-radius: 0 0 30px 30px;
  background-color: var(--green);
  justify-content: center;
  height: 85px;
  padding: 0 !important;
  gap: 9px;
}

.menu {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.button{
  margin-left: 0px !important;
  padding: 0 !important;
}

.navbarLogo{
  width: 100%;
  height: auto;
  max-height: 82px !important;
}

.page {
  background-color: #FFFAE8;
  max-width: 600px;

  /* min-height: calc(100vh - 5em);
  min-height: calc(var(--vh, 1vh) * 100 - 4em); */

  min-height: calc(100vh - 133px);
  margin: 0 auto;
  padding: 24px;
  padding-top: 109px;
  
  /* background-image: url('../assets/images/topBorder.png'); */
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
}

.hideTopBorder {
  background-image: none;
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 430px) {
  .page{
    padding: 97px 12px 12px;
  }
}