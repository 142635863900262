.categoriesScreen {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
}

.categoriesTitleLogo{
  width: 100%;
  max-width: 250px;
  margin: 2rem 0;
}

.categoriesScreen .categoriesTitle {
  font-size: 1.75rem;
  font-family: 'Roboto'; 
  line-height: 1.25;
  font-weight: 500;
  width: 400px;
}

.categoriesOptions {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 2em;
  gap: 15px;
}

div .circularProgress {
  color: var(--orange900);
}

div .dialogButton {
  background-color: var(--orange900);
  color: black;
  font-size: 1rem;
  font-family: 'Roboto'; 
  line-height: 1.2;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  padding: .5em;
  border-radius: 10px;
  width: 50px;
  text-align: center;
}

@media only screen and (max-width: 430px) {
  .categoriesScreen .categoriesTitle {
    font-size: 20px;
    max-width: 100%;
  }

  .categoriesOptions {
    margin-top: 2em;
    width: auto;
    gap: 8px;
  }
}
