.ticketWapper {
  position: relative;
  display: flex;
}

.ticketWapper img{
  max-height: 60px;
  width: auto;
}

.ticketWapper .ticketNumber {
  font-size: 22px;
  font-weight: 800;
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
}
@media only screen and (max-width: 600px) {
  .ticketWapper img{
    max-height: 32px;
  }

  .ticketWapper .ticketNumber {
    font-size: 16px;
  }
}