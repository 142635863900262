.entryScreen {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.entryScreen .clickText {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  color: black;
  margin-top: 24px;
}

.page {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.yellowBitmap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  max-width: 360px;
}

.greenBitmap {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orangeBitmap {
  position: absolute;
  bottom: 0%;
  right: 0;
  max-width: 360px;
  /* transform: translate(50%, 30%); */
}

.entryScreenLogo {
  width: 80%;
  margin-left: -10%;
  z-index: 2;
}

.hide {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .yellowBitmap {
    max-width: 180px;
  }

  .orangeBitmap {
    max-width: 180px;
  }

  .entryScreen .clickText {
    font-size: 22px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 430px) {
  .yellowBitmap {
    max-width: 150px;
  }

  .orangeBitmap {
    max-width: 150px;
  }

  .entryScreen .clickText {
    font-size: 20px;
    margin-top: 10px;
  }
}