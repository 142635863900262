.circle {
  min-width: 390px;
  min-height: 390px;
  width: 390px;
  height: 390px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hasTitle {
  flex-direction: column;
  justify-content: space-around;
}

/* div .contentText {
  color: white;
  margin: auto 2.5em;
  font-size: 1.5rem;
  font-family: 'Cubao' !important;
  line-height: 1.2;
  font-weight: 800; */
/* } */

.circle div .title {
  font-size: 2.5rem;
  margin: .75em 1.5em .25em 1.5em;
}

.isWrong {
  background-color: var(--purple900);
}

.isCorrect {
  background-color: var(--green900);
}

.isQuestion {
  background-color: var(--blue900);
}

.answerBox{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.answerBox img{
  width: 100%;
}

.answerBox .contentText{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
}

.answerBox .contentText .question{
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

.rightAnswer{
  font-weight: 800;
  line-height: 1.25;
}

@media only screen and (max-width: 600px) {
  .circle {
    min-height: 65vw;
    min-width: 65vw;
    height: 65vw;
    width: 65vw;
  }

  div .contentText {
    font-size: 1.25rem;
  }

  .circle div .title { 
    font-size: 2rem;
  }

  .circle div .description { 
    font-size: 1rem;
  }
}

@media only screen and (max-width: 430px) {
  div .contentText { 
    font-size: 1rem;
    font-weight: 600;
  }

  .circle div .title {
    font-size: 1.5rem;
  }

  .circle div .description { 
    font-size: .75rem;
  }
  .rightAnswer{
   font-size: 12px !important;
  }
}