.youChooseContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -54%);
  width: 75%;
  color: white;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.youChooseContent .heading {
  font-size: 34px;
  line-height: 1.25;
  font-weight: 500;
}

.youChooseContent .content {
  font-size: 18px;
  line-height: 1.25;
}

.rightAnserBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.questionAnswers {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  border-radius: 10px;
  gap: 10px;
}

.questionAnswers .questionAnswer {
  background-color: var(--blue);
  font-size: 1.125rem;
  border-radius: 15px;
  text-transform: lowercase !important;
  display: block;
  min-height: 60px;
  font-weight: 400;
}

.questionAnswers .questionAnswer:first-letter {
  text-transform: capitalize !important;
}

.questionAnswers .questionAnswer:hover {
  background-color: var(--purple900);
}

.answerResume {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.incorrectZeek {
  width: 35%;
}

.continueImage {
  width: 120px;
}

.xMarkImage {
  width: 100px;
}

div .circularProgress {
  color: var(--orange900);
}

.continueButton {
  background-color: var(--blue) !important;
  color: white !important;
  border-radius: 15px !important;
  height: 41px !important;
  padding: 10px 44px !important;
  margin-top: 16px !important;
}

@media only screen and (max-width: 600px) {
  .continueImage {
    width: 80px;
  }

  .xMarkImage {
    width: 70px;
  }

  .questionAnswers .questionAnswer {
    font-size: 18px;
    line-height: 22.5px;
  }

  .answerResume .continueButton {
    margin-top: 1em;
    font-size: .875rem;
  }

  .youChooseContent .heading {
    font-size: 28px;
  }

  .youChooseContent .content {
    font-size: 24px;
    line-height: 1.25;
  }

}

@media only screen and (max-width: 430px) {

  .questionBox .contentText {
    font-size: 26px;
    line-height: 1.25;
  }

  .answerResume .continueButton {
    font-size: .75rem;
  }

  .continueImage {
    width: 60px;
  }

  .xMarkImage {
    width: 50px;
  }

  .youChooseContent .heading {
    font-size: 20px;
  }

  .youChooseContent .content {
    font-size: 16px;
    line-height: 1.25;
  }

}