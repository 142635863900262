:root {
  --purple900: #872B3C;
  --green900: #2bb264;
  --blue900: #195174;
  --blue100: #c7dbef;
  --orange900: #DC9851;
  --gray900: #919191;

  --green: #8BB87F;
  --yellow: #EBC239;
  --blue: #195174;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(0,0,0,0.2);
  background-color: #FFFAE8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Cubao';
  src: url('./assets/fonts/cubao/Cubao_Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-Thin.ttf') format('truetype');
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-ExtraLight.ttf') format('truetype');
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-Light.ttf') format('truetype');
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-Regular.ttf') format('truetype');
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-Medium.ttf') format('truetype');
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-SemiBold.ttf') format('truetype');
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-Bold.ttf') format('truetype');
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-ExtraBold.ttf') format('truetype');
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Exa";
  src: url('./assets/fonts/lexend-exa/LexendExa-Black.ttf') format('truetype');
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}


*{
  font-family: "Lexend Exa" !important;
}

.questionBox{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 500px;
}

.questionBox img{
  width: 100%;
}

.screen {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  /* min-height: min(calc(var(--vh, 1vh) * 100 - 4em - 1.4em), 900px); */

  -webkit-animation: fadein 500ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 500ms; /* Firefox < 16 */
   -ms-animation: fadein 500ms; /* Internet Explorer */
    -o-animation: fadein 500ms; /* Opera < 12.1 */
       animation: fadein 500ms;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.questionBox .contentBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -48%);
  z-index: 2;
  width: 75%;
  display: flex;
  flex-direction: column;
  height: 75%;
  align-items: center;
  justify-content: space-evenly;
}

.questionBox .contentBox .contentText{
  font-size: 32px;
  line-height: 40px;
  padding: 0 20px 0 40px;
  font-weight: 600;
}
.questionBox .contentBox .questionText{
  font-size: 24px;
  line-height: 1.25;
  font-weight: 600;
}
.section {
  display: flex;
  margin-top: 2em;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
}

.section .sectionHeading{
  text-align: left;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.section .questionOptions{
  flex: 0 0 60%;
  position: relative;
}

.section .zeekBox{
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
}

.section img {
  width: 100%;
}

.answerTicketWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-box{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
}

@media only screen and (max-width: 600px) {
  .questionBox .contentBox .questionText{
    font-size: 20px;
  }

  .questionBox .contentBox .contentText{
    font-size: 24px;
    line-height: 1.25;
  }
}

@media only screen and (max-width: 430px) {
  .section .questionOptions{
    flex: 0 0 60%;
  }
  
  .section .zeekBox{
    flex: 0 0 40%;
  }

  .questionBox .contentBox .contentText{
    font-size: 22px;
    line-height: 1.25;
  }
  .questionBox .contentBox .questionText{
    font-size: 16px;
  }
}