.errorPage {
  width: 85%;
  text-align: center;
}

.errorPage .errorText {
  font-size: 1rem;
  font-family: 'Roboto';
  line-height: 1.2;
}

.errorPage .errorButton {
  margin-top: .5em;
  color: var(--orange900);
}
