div .circle {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.125em 0;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 4px 4px #00000040;
  padding: 41px 0;
}

div .disabled {
  cursor: unset;
  opacity: 0.2;
  pointer-events: none;
}

.circle .circleText,
.circleCustom .circleText {
  font-family: Roboto;
  line-height: 1;
  color: white;
  font-weight: 500;
  font-size: 28px;
  text-transform: capitalize;
}

.circleCustom{
  width: calc(50% - 8px);
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 0;
  cursor: pointer;
}
.circleTextWrapper{
  flex: 0 0 28px;
  margin-top: 40px;
}
.circleCustom .imgWrapper{
  flex: 1 1 calc(100% - 108px);
  max-width: calc(100% - 108px);
  display: flex;
  margin: 20px 0;
}
.circleCustom .imgWrapper .circleImage{
  width: 100%;
  height: auto;
  aspect-ratio: 1;
}

@media only screen and (max-width: 600px) { 
  .circleTextWrapper{
    flex: 0 0 24px;
    margin-top: 30px;
  }
  .circleCustom .imgWrapper{
    flex: 1 1 calc(100% - 74px);
    max-width: calc(100% - 74px);
    margin: 10px 0;
  }
  .circleCustom .circleText {
    font-size: 24px;
  }
}

@media only screen and (max-width: 430px) {
  div .circle {
    height: 134px;
    width: 134px;
    padding: 25px 0;
  }
  .circleCustom{
    width: calc(50% - 4px);
  }

  .circleTextWrapper{
    flex: 0 0 18px;
    margin-top: 30px;
  }
  .circleCustom .imgWrapper{
    flex: 1 1 calc(100% - 68px);
    max-width: calc(100% - 68px);
    margin: 10px 0;
  }
  .circleCustom .circleText {
    font-size: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .circleCustom .circleText {
    font-size: 18px;
  }
}

